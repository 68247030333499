import { Controller } from "stimulus"
import SimpleLightbox from "simplelightbox";
import "simplelightbox/dist/simple-lightbox.min.css";

export default class extends Controller {
  connect() {
    const gallery = new SimpleLightbox('.trix-content .attachment__link', {
      history: false,
    });
  }
}
