import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['menuIcon', 'link', 'whiteLogo', 'blackLogo', 'whiteToggle', 'blackToggle', 'fullLogo', 'partialLogo', 'blackGlobe', 'whiteGlobe', 'languageSelect'];
  }

  connect() {
    this.sentinel = document.querySelector('#navbar-sentinel');
    if (this.element && this.sentinel) {
      this.navbarObserver = new IntersectionObserver(
        (entries) => {
          if (!entries[0].isIntersecting) {
            // scrolling

            this.element.classList.add('bs-header__nav--enabled');
            if (this.hasMenuIconTarget) {
              this.menuIconTarget.classList.add('bs-header__navbar-menu-icon--enabled');
            }
            this.linkTargets.forEach(link => {
              link.classList.add('bs-header__link--enabled');
            });

            if (this.hasWhiteLogoTarget) {
              this.whiteLogoTarget.classList.remove('bs-header__img-link--enabled');
            }
            if (this.hasBlackLogoTarget) {
              this.blackLogoTarget.classList.add('bs-header__img-link--enabled');
              this.blackLogoTarget.style.display = '';
            }

            if (this.hasWhiteToggleTarget) {
              this.whiteToggleTarget.style.display = 'none';
            }
            if (this.hasBlackToggleTarget) {
              this.blackToggleTarget.style.display = '';
            }

            if (this.hasWhiteGlobeTarget) {
              this.whiteGlobeTarget.style.display = 'none';
            }
            if (this.hasBlackGlobeTarget) {
              this.blackGlobeTarget.style.display = '';
            }
            if (this.hasLanguageSelectTarget) {
              this.languageSelectTarget.classList.add('bs-header__language-select-dropdown--enabled');
            }
          } else {
            // not scrolling, at the top

            this.element.classList.remove('bs-header__nav--enabled');
            if (this.hasMenuIconTarget) {
              this.menuIconTarget.classList.remove('bs-header__navbar-menu-icon--enabled');
            }
            this.linkTargets.forEach(link => {
              link.classList.remove('bs-header__link--enabled');
            });
            if (this.hasWhiteLogoTarget) {
              this.whiteLogoTarget.classList.add('bs-header__img-link--enabled');
            }
            if (this.hasBlackLogoTarget) {
              this.blackLogoTarget.classList.remove('bs-header__img-link--enabled');
              this.blackLogoTarget.style.display = 'none';
            }

            if (this.hasWhiteToggleTarget) {
              this.whiteToggleTarget.style.display = '';
            }
            if (this.hasBlackToggleTarget) {
              this.blackToggleTarget.style.display = 'none';
            }

            if (this.hasWhiteGlobeTarget) {
              this.whiteGlobeTarget.style.display = '';
            }
            if (this.hasBlackGlobeTarget) {
              this.blackGlobeTarget.style.display = 'none';
            }
            if (this.hasLanguageSelectTarget) {
              this.languageSelectTarget.classList.remove('bs-header__language-select-dropdown--enabled');
            }
          }
        },
      );
      this.navbarObserver.observe(this.sentinel);
    }
  }

  disconnect() {
    if (this.navbarObserver && this.sentinel) {
      this.navbarObserver.unobserve(this.sentinel);
    }
  }

  changeLanguage(e) {
    const newLocale = e.target.value;
    const currentLocale = this.data.get('locale') || 'en';

    if (currentLocale !== newLocale) {
      const newUrl = JSON.parse(this.data.get('localizedRoutes'))[newLocale];
      if (newUrl) {
        window.location.href = newUrl;
      } else {
        window.location.reload();
      }
    }
  }
}
