import Rails from "@rails/ujs";
import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return [
      'generalStep',
      'generalStepForm',
      'phoneExt',
      'phoneNumber',
      'inputFirstName',
      'inputLastName',
      'inputCompanyName',
      'inputEmail',
      'checkboxTosAgreed',
      'buttonNext',
      'detailsStep',
      'detailsStepForm',
      'buttonBack',
      'buttonSend',
      'successStep',
      'errorStep',
      'autofocus',
      'captcha',
    ];
  }

  connect() {
    this.formInputs = [
      this.inputFirstNameTarget,
      this.inputLastNameTarget,
      this.inputCompanyNameTarget,
      this.inputEmailTarget,
      this.phoneNumberTarget,
    ];

    if (this.hasPhoneExtTarget && this.hasPhoneNumberTarget) {
      if (this.phoneNumberTarget.value === '') {
        this.phoneNumberTarget.value = `+${this.phoneExtTarget.value}`;
      }
    }

    this.generalStepFormTarget.addEventListener('focus', this.onGeneralStepFormFocus, true)
    this.generalStepFormTarget.addEventListener('ajax:error', this.onGeneralStepFormError, true)
    this.generalStepFormTarget.addEventListener('ajax:success', this.onGeneralStepFormSuccess, true)
    this.detailsStepFormTarget.addEventListener('ajax:error', this.onDetailsStepFormError, true)
    this.detailsStepFormTarget.addEventListener('ajax:success', this.onDetailsStepFormSuccess, true)
    this.buttonNextTarget.addEventListener('click', this.onClickNext, true)
    this.buttonBackTarget.addEventListener('click', this.onClickBack, true)
    this.buttonSendTarget.addEventListener('click', this.onClickSend, true)
    this.phoneExtTarget.addEventListener('change', this.onChangePhoneExt, true)
  }

  disconnect() {
    this.generalStepFormTarget.removeEventListener('focus', this.onGeneralStepFormFocus, true)
    this.generalStepFormTarget.removeEventListener('ajax:error', this.onGeneralStepFormError, true)
    this.generalStepFormTarget.removeEventListener('ajax:success', this.onGeneralStepFormSuccess, true)
    this.detailsStepFormTarget.removeEventListener('ajax:error', this.onDetailsStepFormError, true)
    this.detailsStepFormTarget.removeEventListener('ajax:success', this.onDetailsStepFormSuccess, true)
    this.buttonNextTarget.removeEventListener('click', this.onClickNext, true)
    this.buttonBackTarget.removeEventListener('click', this.onClickBack, true)
    this.buttonSendTarget.removeEventListener('click', this.onClickSend, true)
    this.phoneExtTarget.removeEventListener('change', this.onChangePhoneExt, true)
  }

  fieldsMap(id) {
    return {
      first_name: this.inputFirstNameTarget,
      last_name: this.inputLastNameTarget,
      company: this.inputCompanyNameTarget,
      email: this.inputEmailTarget,
      phone: this.phoneNumberTarget,
      tos_agreed: this.checkboxTosAgreedTarget,
      captcha: this.captchaTarget,
    }[id];
  }

  showStep(stepName) {
    this.generalStepTarget.style.display = stepName === 'general' ? '' : 'none';
    this.detailsStepTarget.style.display = stepName === 'details' ? '' : 'none';
    this.successStepTarget.style.display = stepName === 'success' ? '' : 'none';
    this.errorStepTarget.style.display = stepName === 'error' ? '' : 'none';
  }

  openModal() {
    this.showStep('general')

    if (this.hasAutofocusTarget) {
      this.inputFirstNameTarget.focus();
    }
  }

  onClickNext = (_event) => {
    Rails.fire(this.generalStepFormTarget, 'submit');
  }

  onGeneralStepFormSuccess = (event) => {
    const { detail } = event;
    const id = detail[0].id;

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.reset();
    }

    this.showStep('details')

    this.detailsStepFormTarget.action = `/briefings/${id}.json`;
  }

  onGeneralStepFormError = (event) => {
    const { detail } = event;
    const errors = detail[0].errors;

    this.element.querySelectorAll('.bs-form__field-error').forEach((errorField) => {
      errorField.remove();
    });

    Object.keys(errors).forEach((id) => {
      const message = errors[id];
      const inputEl = this.fieldsMap(id);
      const fieldError = `<div class="bs-form__field-error">${message}</div>`;

      if (inputEl) {
        inputEl.classList.toggle('invalid', true);
        inputEl.parentElement.insertAdjacentHTML('beforeend', fieldError);
      } else {
        console.log(`Couldn't find ${id} element.`)
      }
    });

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.reset();
    }
  }

  onGeneralStepFormFocus = (event) => {
    this.removeExistingErrorMessage(event.target)
  }

  onClickBack = () => {
    this.showStep('general')
  }

  onClickSend = (_event) => {
    Rails.fire(this.detailsStepFormTarget, 'submit');

    if (dataLayer) {
      dataLayer.push({ event: 'InquirySubmit' });
    }
  }

  onDetailsStepFormSuccess = () => {
    this.formInputs.forEach((formInput) => {
      if (formInput) {
        formInput.value = '';
      }
    });

    this.showStep('success');
  }

  onDetailsStepFormError = (event) => {
    this.showStep('error')
  }

  onChangePhoneExt = (event) => {
    if (this.hasPhoneNumberTarget) {
      this.phoneNumberTarget.value = `+${event.target.value}`;
    }
  }

  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.bs-form__field')
    if(!fieldContainer)
      return;

    const existingErrorMessageElement = fieldContainer.querySelector('.bs-form__field-error')
    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }
}
