import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['template', 'container', 'button'];

  connect() {
    this.resetContainers();

    this.items = JSON.parse(this.data.get('items'));
    this.loaded = 0;

    const initialSize = Number(this.data.get('initialSize'));
    for (let i = 0; i < initialSize; i++) {
      const item = this.items[this.loaded];
      this.loadItem(item);
    }
  }

  resetContainers() {
    this.containerTargets.forEach(container => {
      container.innerHTML = '';
    });
  }

  loadMore() {
    const loadSize = Number(this.data.get('loadSize'))

    Array.from(Array(loadSize)).map((_, i) => {
      const item = this.items[this.loaded];
      if (item) {
        this.loadItem(item);
      } else {
        this.buttonTarget.style.display = 'none';
      }
    });
  }

  loadItem(item) {
    if (!item) {
      return;
    }

    let templateString = this.templateTarget.innerHTML;
    const vars = Object.keys(item);
    vars.forEach((name) => {
      templateString = templateString.replaceAll(`\${${name}}`, item[name]);
    });

    if (!item['targets']) {
      console.error(`item doesn not have "targets" key: ${item}`);
    }

    item.targets.forEach((target) => {
      const container = this.element.querySelector(`*[data-target="load-more.container"][data-container-name="${target}"]`)
      if (container) {
        this.stringToDom(container, templateString);
      } else {
        console.error(`container ${target} not found`);
      }
    });

    this.loaded += 1;

    if (this.loaded >= this.items.length) {
      this.buttonTarget.style.display = 'none';
    }
  }

  stringToDom(dom, string) {
    dom.appendChild(document.createRange().createContextualFragment(string));
  }
}
