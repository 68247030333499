import { Controller } from "stimulus"
import { Swiper } from 'swiper';
import 'swiper/swiper-bundle.css';
import lozad from 'lozad';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    const observer = lozad();

    const mySwiper = new Swiper(this.containerTarget, {
      slidesPerView: 'auto',
      spaceBetween: 30,
      loop: true,
    });

    observer.observe();

    mySwiper.on('slideChange', function () {
      observer.observe();
    });
  }
}
