import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static get targets() {
    return ['accept']
  }

  connect() {
    document.addEventListener('turbolinks:load', this.setupCookiesBar);
  }

  setupCookiesBar = () => {
    this.acceptTarget.onclick = () => {
      this.element.remove();
      Cookies.set('allow_cookies', 'true', { expires: 365 });
    };
  }
}


