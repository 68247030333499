import { Controller } from "stimulus"
import { Swiper, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import lozad from 'lozad';

Swiper.use([Navigation, Pagination]);

export default class extends Controller {
  static targets = ['container', 'pagination', 'next', 'prev'];

  connect() {
    const observer = lozad();

    const mySwiper = new Swiper(this.containerTarget, {
      slidesPerView: 1,
      spaceBetween: 50,
      loop: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      },
      pagination: {
        el: this.paginationTarget,
      },
    });

    observer.observe();

    mySwiper.on('slideChange', function () {
      observer.observe();
    });
  }
}
