import { Controller } from "stimulus"
import { Swiper, Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import lozad from 'lozad';

Swiper.use([Navigation, Pagination, Autoplay]);

const slidesPerViewBreakpoints = {
  600: 3,
  800: 4,
  1100: 5,
  1400: 6,
}

export default class extends Controller {
  static targets = ['container', 'pagination', 'next', 'prev'];

  connect() {
    const observer = lozad();

    const mySwiper = new Swiper(this.containerTarget, {
      slidesPerView: 2,
      spaceBetween: 50,
      autoplay: {
        delay: 4000,
      },
      loop: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      breakpoints: Object.keys(slidesPerViewBreakpoints).reduce((memo, key) => { memo[key] = { slidesPerView: slidesPerViewBreakpoints[key] }; return memo; }, {})
    });

    observer.observe();

    mySwiper.on('slideChange', function () {
      observer.observe();
    });
  }
}
