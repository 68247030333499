// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Stimulus setup
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
const context_components = require.context("../../components", true, /_controller.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(context_components)
  )
)


/**
 * Will be extracted and imported with stylesheet_packs_with_chunks_tag 'application'
 */
import '../stylesheets/application.scss';
const images = require.context('../images', true)

/**
 * Will load the view_components javascript and css files
 */
function importAll(r) {
  r.keys().forEach(r)
}

importAll(require.context("../../components", true, /_component.js$/))
importAll(require.context("../../components", true, /_component.scss$/))

/* Lozad initialization */

import lozad from 'lozad';

const initLozad = () => {
  const observer = lozad();
  observer.observe();
};

document.addEventListener('turbolinks:load', initLozad)

// Dont need yet
// require("trix");
// require("@rails/actiontext");
