import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    const modalId = `modal-${this.data.get('id')}`;
    this.modal = document.getElementById(modalId);
  }

  disconnect() {
  }

  open() {
    this.modal.style.display = '';
    this.modal.classList.add('bs-modal--active');
    document.body.classList.add('bs-modal--open');

    const event = new CustomEvent('open-modal');
    window.dispatchEvent(event);
  }

  close() {
    const activeModal = document.getElementsByClassName('bs-modal--active')[0];

    if (activeModal) {
      activeModal.style.display = 'none';
      activeModal.classList.remove('bs-modal--active');
      document.body.classList.remove('bs-modal--open');
    }
  }
}
