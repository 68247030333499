import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    this.element.value = document.location.href;
  }

  disconnect() {
  }
}
