import Rails from "@rails/ujs";
import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return [
      'form', 'phoneExt', 'phoneNumber', 'inputFirstName', 'inputLastName',
      'inputCompanyName', 'inputEmail', 'checkboxTosAgreed', 'submitButton',
      'captcha', 'message'
    ];
  }

  fieldsMap(id) {
    return {
      first_name: this.inputFirstNameTarget,
      last_name: this.inputLastNameTarget,
      company: this.inputCompanyNameTarget,
      email: this.inputEmailTarget,
      phone: this.phoneNumberTarget,
      tos_agreed: this.checkboxTosAgreedTarget,
      captcha: this.captchaTarget,
    }[id];
  }

  connect() {
    this.formInputs = [
      this.inputFirstNameTarget,
      this.inputLastNameTarget,
      this.inputCompanyNameTarget,
      this.inputEmailTarget,
      this.phoneNumberTarget,
      this.messageTarget,
    ];

    if (this.hasPhoneExtTarget && this.hasPhoneNumberTarget) {
      if (this.phoneNumberTarget.value === '') {
        this.phoneNumberTarget.value = `+${this.phoneExtTarget.value}`;
      }
    }

    this.formTarget.addEventListener('focus', this.onFocus, true)
    this.formTarget.addEventListener('ajax:error', this.onFormError, true)
    this.formTarget.addEventListener('ajax:success', this.onFormSuccess, true)
    this.submitButtonTarget.addEventListener('click', this.onSubmit, true)
    this.phoneExtTarget.addEventListener('change', this.onChangePhoneExt, true)
  }

  disconnect() {
    this.formTarget.removeEventListener('focus', this.onFocus)
    this.formTarget.removeEventListener('ajax:error', this.onFormError)
    this.formTarget.removeEventListener('ajax:success', this.onFormSuccess)
    this.submitButtonTarget.removeEventListener('click', this.onSubmit)
    this.phoneExtTarget.removeEventListener('change', this.onChangePhoneExt, true)
  }

  onFormError = (event) => {
    const { detail } = event;
    const errors = detail[0].errors;

    this.element.querySelectorAll('.bs-form__field-error').forEach((errorField) => {
      errorField.remove();
    });

    Object.keys(errors).forEach((id) => {
      const message = errors[id];
      const inputEl = this.fieldsMap(id);
      const fieldError = `<div class="bs-form__field-error">${message}</div>`;

      if (inputEl) {
        inputEl.classList.toggle('invalid', true);
        inputEl.parentElement.insertAdjacentHTML('beforeend', fieldError);
      } else {
        console.log(`Couldn't find ${id} element.`)
      }
    });

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.reset();
    }
  }

  onFormSuccess = () => {
    this.modal = document.getElementById('modal-success');
    this.modal.style.display = '';
    this.modal.classList.add('bs-modal--active');
    document.body.classList.add('bs-modal--open');

    this.formInputs.forEach((formInput) => {
      formInput.value = '';
    });

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.reset();
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    Rails.fire(this.formTarget, 'submit');
  }

  onChangePhoneExt = (e) => {
    if (this.hasPhoneNumberTarget) {
      this.phoneNumberTarget.value = `+${e.target.value}`;
    }
  }

  onFocus = (event) => {
    this.removeExistingErrorMessage(event.target)
  }

  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.bs-form__field')

    if (!fieldContainer) return;

    const existingErrorMessageElement = fieldContainer.querySelector('.bs-form__field-error');

    if (existingErrorMessageElement) {
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
    }
  }
}
