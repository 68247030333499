import { Controller } from "stimulus";

const activeArticleClass = "bs-stories__article--active";
const activeImageClass = "bs-stories__image--active";
const hideImageClass = "bs-stories__image--hide";
const imageSelector = ".bs-stories__image";

export default class extends Controller {
  static targets = ["articles", "images"];

  connect() {
    this.handleArticleHover();
    this.initializeActiveArticle();
  }

  handleArticleHover() {
    this.articlesTarget.querySelectorAll("li").forEach((article) => {
      article.addEventListener("mouseenter", (event) => {
        const { currentTarget } = event;
        const { storyId } = currentTarget.dataset;

        // blue border
        this.articlesTarget.querySelectorAll("li").forEach((article) => {
          article.classList.remove(activeArticleClass);
        });

        event.currentTarget.classList.toggle(activeArticleClass);

        // show image
        const storyImage = this.imagesTarget.querySelector(
          `img[data-story-id='${storyId}']`
        );

        this.imagesTarget.querySelectorAll(imageSelector).forEach((image) => {
          image.classList.remove(activeImageClass);
          image.classList.add(hideImageClass);
        });

        storyImage.classList.add(activeImageClass);
        storyImage.classList.remove(hideImageClass);
      });
    });
  }

  initializeActiveArticle() {
    this.articlesTarget.querySelector("li").classList.add(activeArticleClass);
    this.imagesTarget
      .querySelector(imageSelector)
      .classList.add(activeImageClass);
    this.imagesTarget
      .querySelector(imageSelector)
      .classList.remove(hideImageClass);
  }
}
