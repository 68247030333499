import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['header', 'answer', 'toggle'];
  }

  connect() {
    if (this.data.get('active') === 'yep') {
      this.open(false);
    } else {
      this.close(false);
    }
  }

  toggle(e) {
    if (this.data.get('active') === 'yep') {
      this.close();
    } else {
      this.open();
    }
  }

  open(changeState = true) {
    this.answerTarget.classList.toggle('lp-faq__qna-answer-container--hidden', false);
    this.headerTarget.classList.toggle('lp-faq__header--active', true);
    this.toggleTarget.classList.toggle('lp-faq__toggle--active', true);

    if (changeState) {
      this.data.set('active', 'yep');
    }
  }

  close(changeState = true) {
    this.answerTarget.classList.toggle('lp-faq__qna-answer-container--hidden', true);
    this.headerTarget.classList.toggle('lp-faq__header--active', false);
    this.toggleTarget.classList.toggle('lp-faq__toggle--active', false);

    if (changeState) {
      this.data.set('active', 'nope');
    }
  }

  // disconnect() {}
}
